import DGridImageTile from '@components/DGridImageTile/DGridImageTile';
import DMasorny from '@components/DMasorny/DMasorny';
import DOverlay from '@components/DOverlay/DOverlay';
import DSlider from '@components/DSlider/DSlider';
import '@fragments/SImage';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo, useState } from 'react';
import './DImageGrid.scss';
export default function DImageGrid({ data: { images }, dimensions }) {

    const [showOverlay, setShowOverlay] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(-1);

    const onClickImage = (index) => {
        setShowOverlay(true);
        setCurrentImageIndex(index);
    }

    const onCloseOverlay = () => {
        setShowOverlay(false);
        setCurrentImageIndex(-1);
    }

    const allGridImages = useMemo(() => images.map((image, index) => ({
        gatsbyImage: getImage(image.imageFile),
        index,
        ...image
    })), [images]);

    const masornyTiles = useMemo(() => allGridImages.map((image, index) =>
        <DGridImageTile key={`d-image-grid-image-${index}`}
            gatsbyImage={image.gatsbyImage} alternativeText={image.alternativeText}
            onClick={() => onClickImage(index)} />
    ), [allGridImages]);

    return (
        <div className="d-image-grid w-100 d-flex justify-center">
            <div className="image-grid-wrap">
                <DOverlay show={showOverlay} onClose={onCloseOverlay}>
                    <DSlider startFromIndex={currentImageIndex} images={allGridImages} />
                </DOverlay>
                <DMasorny dimensions={dimensions} >
                    {masornyTiles}
                </DMasorny>
            </div>
        </div>
    )
}

DImageGrid.defaultProps = {
    dimensions: { xs: 6, sm: 6, md: 4, lg: 4 }
}


export const query = graphql`
    fragment SImageGrid on DSTRAPI_ComponentImageImageGrid {
        images {
            ... SImage
        }
    }`