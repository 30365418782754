import PropTypes from 'prop-types';
import React from 'react';
import './DBtn.scss';

export default function DBtn ({ children, onClick, size, bgColor, color, type, disabled, className }) {

    const typeClass = type;
    const bgColorClass = `bg-${bgColor}`;
    const colorClass = `color-${color}`;
    const sizeClass = `btn-${size}`;
    const disabledClass = disabled ? `visibility-hidden` : '';

    return (
        <button onClick={onClick} disabled={disabled} className={`d-btn ${sizeClass} ${typeClass} ${bgColorClass} ${colorClass} ${disabledClass} cursor-pointer d-flex align-center justify-center ${className || ''}`}>
            {children}
        </button>
    );
}

DBtn.defaultProps = {
    size: 'm',
    color: 'white',
    bgColor: 'primary',
    type: 'btn',
    disabled: false
}

DBtn.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    type: PropTypes.oneOf(['btn', 'icon']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}