const breakpoint = {
    sm: 576,
    md: 768,
    lg: 992
}

const getViewport = () => {
    if (typeof window != 'undefined') {
        const { sm, md, lg } = breakpoint;
        const windowWidth = window.innerWidth;
        if (windowWidth < sm) return 'xs';
        if (windowWidth < md) return 'sm';
        if (windowWidth < lg) return 'md';
        return 'lg';
    }
    return 'lg';
}

export { breakpoint, getViewport }