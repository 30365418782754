import React from 'react'
import './DMain.scss'

export default function DMain ({ children }) {
    return (
        <main className="d-main">
            <div className="w-100">
                {children}
            </div>
        </main>
    )
}