import DBtn from '@components/DBtn/DBtn';
import DIcon from '@components/DIcon/DIcon';
import { lock, release } from '@mixins/lockScroll';
import React, { useEffect } from 'react';
import './DOverlay.scss';

export default function DOverlay (props) {
    const { children, show, onClose, title } = props;

    useEffect(() => {
        show ? lock() : release();
    }, [show]);

    useEffect(() => {
        const closeOnEscape = (e) => {
            if (e.key === 'Escape' || e.key === 'Esc') onClose();
        }

        window.addEventListener('keydown', closeOnEscape);

        return () => {
            window.removeEventListener('keydown', closeOnEscape);
        }
    }, [onClose]);

    if (!show) return null;
    return (
        <div className="d-overlay bg-white d-flex flex-column overflow-hidden">
            <div className="position-relative">
                <DBtn onClick={onClose} type="icon" className="close-btn position-absolute text-uppercase color-grey" color="grey" bgColor="transparent" >
                    <DIcon icon="close" />
                </DBtn>
            </div>
            <div className="overlay-content d-flex justify-center overflow-auto">
                {children}
            </div>
        </div>
    )
}
