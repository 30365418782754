import md from '@plugins/markdown-it';
import React from 'react';
import './DMdContent.scss';

export default function DMdContent ({ content, className }) {
    const contentHtml = md.render(content);
    return (
        <div className={`d-md-content d-flex justify-center px-2 ${className || ''}`}>
            <div className="content w-100" dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
        </div>
    )
}