import React from 'react'
import './DApp.scss'

export default function DApp ({ children }) {
    return (
        <div className="d-application bg-white">
            <div className="d-application-wrap">
                {children}
            </div>
        </div>
    )
}