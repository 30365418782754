import { graphql, Link } from 'gatsby';
import React from 'react';
import './DFooter.scss';

export default function DFooter ({ data: { links } }) {
    const navLinks = links.map((link, index) =>
        <Link key={`footer-link-${index}`} className="footer-link color-primary--darken mx-1 d-flex align-center" to={`/${link.page.slug}`}>
            {link.title}
        </Link>);
    return (
        <footer className="d-footer d-flex justify-center px-2">
            {navLinks}
        </footer>
    )
}

export const query = graphql`
    fragment SFooter on DSTRAPI {
        footer {
            links {
                title
                page {
                    slug
                }
            }
        }
    }`