import FaBehance from '@assets/svg/FaBehance.inline.svg';
import FaEtsy from '@assets/svg/FaEtsy.inline.svg';
import MdiAbugidaThai from '@assets/svg/MdiAbugidaThai.inline.svg';
import MdiChevronDown from '@assets/svg/MdiChevronDown.inline.svg';
import MdiChevronLeft from '@assets/svg/MdiChevronLeft.inline.svg';
import MdiChevronRight from '@assets/svg/MdiChevronRight.inline.svg';
import MdiClose from '@assets/svg/MdiClose.inline.svg';
import MdiEmailOutline from '@assets/svg/MdiEmailOutline.inline.svg';
import MdiGestureSwipe from '@assets/svg/MdiGestureSwipe.inline.svg';
import MdiHamburger from '@assets/svg/MdiHamburger.inline.svg';
import MdiInstagram from '@assets/svg/MdiInstagram.inline.svg';
import MdiPinterest from '@assets/svg/MdiPinterest.inline.svg';
import React from 'react';
import './DIcon.scss';

export default function DIcon ({ icon, color, className, size }) {

    const iconSizeClass = `icon-${size}`;
    const iconColorClass = `color-${color}`;

    const iconElement = (() => {
        if (icon === 'abugida-thai') return <MdiAbugidaThai />;
        if (icon === 'chevron-left') return <MdiChevronLeft />;
        if (icon === 'chevron-right') return <MdiChevronRight />;
        if (icon === 'chevron-down') return <MdiChevronDown />;
        if (icon === 'close') return <MdiClose />;
        if (icon === 'gesture-swipe') return <MdiGestureSwipe />;
        if (icon === 'hamburger') return <MdiHamburger />;
        if (icon === 'email') return <MdiEmailOutline />;
        if (icon === 'instagram') return <MdiInstagram />;
        if (icon === 'behance') return <FaBehance />;
        if (icon === 'pinterest') return <MdiPinterest />;
        if (icon === 'etsy') return <FaEtsy />;
    })();

    return (
        <span className={`d-icon ${iconSizeClass} ${iconColorClass} ${className || ''}`}>
            {iconElement}
        </span>
    )
}

DIcon.defaultProps = {
    size: 'm',
    icon: 'abugida-thai',
    color: 'default',
}