import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import './DGridImageTile.scss';

export default function DGridImageTile({ gatsbyImage, alternativeText, tileTitle, onClick }) {

    return (
        <div className="d-grid-image-tile cursor-pointer pa-1" onClick={onClick}>
            <div className="d-flex justify-center align-center flex-column position-relative">
                <GatsbyImage sizes="(max-width: 767px) 50vw, (max-width: 1399px) 33vw, 450px" className="rounded" image={gatsbyImage} alt={alternativeText} imgStyle={{ objectFit: `scale-down` }} />
                {tileTitle && <div className="title-wrap position-absolute w-100">
                    <div className="bg-white color-grey text-center text-label">{tileTitle}</div>
                </div>}
            </div>
        </div>
    )
}