import DBtn from '@components/DBtn/DBtn'
import DIcon from '@components/DIcon/DIcon'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './DSlider.scss'

export default function DSlider (props) {
    const { images, startFromIndex, loadMore } = props;

    const [currentIndex, _setCurrentIndex] = useState(startFromIndex);

    const currentIndexRef = useRef(currentIndex);
    const swipe = useRef({ start: undefined, end: undefined, xPos: undefined });
    let slidesContainer = useRef(null);
    let slides = useRef(null);

    const slideTansition = 'transform 0.3s ease-in-out';

    const onKeyDown = e => {
        if (e.keyCode === 37) {
            if (isLeftEdgeSlide()) return;
            prev();
            return;
        }
        if (e.keyCode === 39) {
            if (isRightEdgeSlide()) return;
            next();
            return;
        }
    }

    useEffect(() => {
        slides.current.style.transform = `translateX(${-(100 + getGapPercentage()) * currentIndex}%)`;
    }, [currentIndex]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => { window.removeEventListener('keydown', onKeyDown); }
    }, []);

    const setCurrentIndex = (val) => {
        currentIndexRef.current = val;
        _setCurrentIndex(val);
    }

    const getGapPercentage = () => 1 / slidesContainer.current.clientWidth * 100;
    const getMoveDirection = (start, end) => end < start ? 'next' : 'prev';
    const getMoveDistance = (start, end, container) => Math.min(Math.abs(start - end), container.clientWidth);
    const isRightEdgeSlide = () => !(currentIndexRef.current < images.length - 1);
    const isLeftEdgeSlide = () => !(currentIndexRef.current > 0);
    const releaseSlide = () => {
        slides.current.style.transition = slideTansition;
        slides.current.style.transform = `translateX(${-(100 + getGapPercentage()) * currentIndex}%)`;
    }

    const next = () => {
        const nextIndex = currentIndexRef.current + 1;
        slides.current.style.transition = slideTansition;
        slides.current.style.transform = `translateX(${-(100 + getGapPercentage()) * nextIndex}%)`;
        setCurrentIndex(nextIndex);
    };
    const prev = () => {
        const prevIndex = currentIndexRef.current - 1;
        slides.current.style.transition = slideTansition;
        slides.current.style.transform = `translateX(${-(100 + getGapPercentage()) * prevIndex}%)`;
        setCurrentIndex(prevIndex);
    };

    const onTouchMove = ev => {
        const { start, xPos } = swipe.current;
        swipe.current.xPos = ev.changedTouches[0]?.clientX;

        let totalMovement = -(100 + getGapPercentage()) * currentIndex;
        const movedPercentage = getMoveDistance(start, xPos, slidesContainer.current) / slidesContainer.current.clientWidth * 100;
        const direction = getMoveDirection(start, xPos);
        if (direction === 'next') totalMovement -= movedPercentage;
        if (direction === 'prev') totalMovement += movedPercentage;
        slides.current.style.transition = 'none';
        slides.current.style.transform = `translateX(${totalMovement}%)`;
    }

    const onTouchStart = (ev) => {
        swipe.current.start = ev.changedTouches[0]?.clientX;
    }

    const onTouchEnd = (ev) => {
        swipe.current.end = ev.changedTouches[0]?.clientX;
        const { start, end } = swipe.current;
        const direction = getMoveDirection(start, end);
        const moveDistance = getMoveDistance(start, end, slidesContainer.current);
        const minMoveTreshold = 20;
        if (moveDistance < minMoveTreshold) {
            releaseSlide();
            return;
        }

        if (direction === 'next' && isRightEdgeSlide()) {
            releaseSlide();
            return;
        }
        if (direction === 'prev' && isLeftEdgeSlide()) {
            releaseSlide();
            return;
        }
        if (direction === 'next') next();
        if (direction === 'prev') prev();
    }

    const onTransitionEnd = () => {
        loadMore(currentIndexRef.current);
    }

    const imageEls = useMemo(() => images.map((image, index) =>
        <GatsbyImage className="w-100" key={index} image={image.gatsbyImage} alt={image.alternativeText} imgStyle={{ objectFit: 'scale-down' }} />
    ), [images]);

    return (
        <div className="d-slider d-flex justify-center align-center flex-column align-center" onTouchMove={onTouchMove} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
            <div className="slider-top-bar d-flex align-center text-title">
                {images[currentIndex].caption}
            </div>
            <div className="slider-wrap d-flex align-center">
                <SlideArrow icon="chevron-left" onClick={prev} disabled={isLeftEdgeSlide} />
                <div ref={slidesContainer} className="slider-slides-container d-flex h-100 overflow-hidden">
                    <div ref={slides} onTransitionEnd={onTransitionEnd} className="slider-slides d-flex flex-row">
                        {imageEls}
                    </div>
                </div>
                <SlideArrow icon="chevron-right" onClick={next} disabled={isRightEdgeSlide} />
            </div>
        </div>
    )
}

function SlideArrow ({ onClick, disabled, icon }) {
    return (
        <DBtn onClick={onClick} className="slider-direction px-0" disabled={disabled()} bgColor="transparent">
            <DIcon icon={icon} size="l" />
        </DBtn>
    )
}

DSlider.defaultProps = {
    loadMore: () => { }
}

DSlider.propTypes = {
    startFromIndex: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    loadMore: PropTypes.func
}