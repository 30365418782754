import { graphql } from 'gatsby';
import React from 'react';
import './DDivider.scss';

export default function DDivider ({ data: { offsetTop, offsetBottom } }) {

    return (
        <div className={`d-divider w-100 d-flex justify-center mt-${offsetTop || '4'} mb-${offsetBottom || '4'}`}>
            <div className='divider-line bg-highlight'></div>
        </div>
    )
}

DDivider.defaultProps = {
    data: {}
}

export const query = graphql`
    fragment SDivider on DSTRAPI_ComponentGeneralDivider {
        offsetTop
        offsetBottom
    }`