import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function DSeo ({ title, otherTags }) {

    if (!title && !otherTags.length) return null;
    const metaTags = otherTags.map((metaTag, index) => <meta key={index} name={metaTag.name} content={metaTag.content || undefined} />)

    return (
        <Helmet title={title}>
            {metaTags}
        </Helmet>
    )
}

DSeo.defaultProps = {
    title: 'Dasha\'s Site',
    otherTags: []
}

DSeo.propTypes = {
    title: PropTypes.string,
    otherTags: PropTypes.array
}