let scrollY = 0;

const lock = () => {
    scrollY = window.scrollY;
    document.documentElement.classList.add('lock-html');
    document.body.classList.add('lock-body');
    document.body.style.top = `-${scrollY}px`;
}

const release = () => {
    document.documentElement.classList.remove('lock-html');
    document.body.classList.remove('lock-body');
    document.body.style.top = '';
    window.scrollTo(0, scrollY);
}

export { lock, release }