import { getViewport } from '@mixins/responsive';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

const createArrayOfArrays = (arrays) => {
    let arr = [];
    for (let i = 0; i < arrays; i++) { arr.push([]); }
    return arr;
}

export default function DMasorny ({ children, dimensions }) {

    const [viewport, setViewport] = useState('');

    useLayoutEffect(() => { setViewport(getViewport()); }, []);

    useEffect(() => {
        const onResize = () => {
            setViewport(getViewport());
        }
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const masornyCols = useMemo(() => {
        const colElements = {};

        for (const dim in dimensions) {
            const currentDimVal = dimensions[dim];
            const currentDimArray = createArrayOfArrays(12 / currentDimVal);

            let colCnt = 0;
            children.forEach(el => {
                currentDimArray[colCnt].push(el);
                colCnt++;
                if (colCnt === currentDimArray.length) colCnt = 0;
            });

            colElements[dim] = currentDimArray.map((col, index) => {
                return <div className={`col-${currentDimVal}`} key={`d-masorny-grid-col-${index}`}>{col}</div>
            });
        }
        return colElements;
    }, [children, dimensions]);

    return (
        <div className="d-masorny">
            <div className="row">
                {masornyCols[viewport]}
            </div>
        </div>
    )
}