import DBtn from '@components/DBtn/DBtn';
import DIcon from '@components/DIcon/DIcon';
import DMainNav from '@components/DMainNav/DMainNav';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import './DHeader.scss';

export default function DHeader ({ backLink, title, canGoBack }) {
    const [showNavMenu, setShowNavMenu] = useState(false);

    const openNavMenu = () => setShowNavMenu(true);
    const closeNavMenu = () => setShowNavMenu(false);
    const goBack = (()=> {
        if(canGoBack) return () => navigate(-1);
        if(backLink) return () => navigate(backLink.path, { replace: true });
        return null;
    })();

    return (
        <header className="d-header d-flex space-between bg-white w-100">
            <DMainNav show={showNavMenu} onClose={closeNavMenu} />
            <div className="back-link d-flex align-center color-primary--darken">
                {backLink &&
                    <DBtn className="d-flex align-center px-0" onClick={goBack} bgColor="transparent" color="primary--darken">
                        <DIcon icon="chevron-left" color="primary--darken" />
                        {backLink.title && <span>{backLink.title}</span>}
                    </DBtn>}
            </div>
            <div className="text-title mx-2 d-flex align-center">
                {title && <div>{title}</div>}
            </div>
            <div className="burger d-flex align-center justify-end">
                <DBtn className="px-0" onClick={openNavMenu} bgColor="transparent">
                    <DIcon icon="hamburger" color="primary--darken" />
                </DBtn>
            </div>
        </header>
    )
}