import DBtn from '@components/DBtn/DBtn';
import DIcon from '@components/DIcon/DIcon';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import './DMainNav.scss';

export default function DMainNav ({ show, onClose }) {
    useEffect(() => {
        const closeOnEscape = (e) => {
            if (e.key === 'Escape' || e.key === 'Esc') onClose();
        }

        window.addEventListener('keydown', closeOnEscape);

        return () => {
            window.removeEventListener('keydown', closeOnEscape);
        }
    }, [onClose]);

    const { dStrapi: { mainNav: mainNavData } } = useStaticQuery(graphql`
        query getMainNavData {
            dStrapi {
                mainNav {
                    main {
                        title
                        page {
                            slug
                        }
                        anchor
                    }
                    footer {
                    title
                        page {
                            slug
                        }
                    }
                }
            }
        }
    `);

    const mainLinks = useMemo(() => mainNavData.main.map((navItem, index) => {
        let linkTo = navItem.page.slug === 'index' ? '/' : `/${navItem.page.slug}`;
        const hasAnchor = navItem.anchor ? true : false;
        if (hasAnchor) linkTo += navItem.anchor;
        return (
            <Link key={index} className="py-2" to={linkTo} activeClassName="active"
                replace={hasAnchor ? true : false} onClick={onClose}>{navItem.title}</Link>
        )
    }), [mainNavData.main, onClose]);

    const footerLinks = useMemo(() => mainNavData.footer.map((navItem, index) =>
        <Link key={index} className="py-1" to={`/${navItem.page.slug}`} activeClassName="active">{navItem.title}</Link>), [mainNavData.footer]);

    return (
        show && <div className="d-nav-menu d-flex">
            <div onClick={onClose} className="dimming-overlay bg-primary"></div>
            <div className="menu d-flex flex-column h-100 align-center bg-white">
                <div className="controls-area w-100">
                    <DBtn className="ml-auto px-0" onClick={onClose} bgColor="transparent">
                        <DIcon icon="close" color="primary--darken" />
                    </DBtn>
                </div>
                <nav className="main-links d-flex flex-column justify-center silver-belly color-primary--darken align-center py-3">
                    {mainLinks}
                </nav>
                <div className="d-flex flex-column align-center color-primary--darken">
                    {footerLinks}
                </div>
            </div>
        </div>
    )
}

DMainNav.defaultProps = {
    show: false,
    onClose: () => { console.log('no close defined') }
}

DMainNav.propTypes = {
    show: PropTypes.bool
}